<template>
	<div class="anchor-email" :class="{ 'inline-block': inline }">
		<Fa v-if="showIcon" weight="r" icon="envelope" />
		<a :href="`mailto:${email}`" v-if="email" class="anchor-email__link">{{ email }}</a>
		<span v-else>-</span>
	</div>
</template>

<script>
export default {
	name: 'AppAnchorEmail',
	props: {
		email: String,
		inline: {
			type: Boolean,
			default: false,
		},
		showIcon: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.anchor-email {
	display: flex;
	align-items: center;
	gap: 10px;
}

.anchor-email__link {
	font-weight: 400;
}

.inline-block {
	display: inline-block;
}
</style>
