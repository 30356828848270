<template>
	<div class="anchor-tel" :class="{ 'inline-block': inline }">
		<Fa v-if="showIcon" weight="r" icon="phone" />
		<a :href="`tel:${tel}`" v-if="tel" class="anchor-tel__link">{{ tel }}</a>
		<span v-else>-</span>
	</div>
</template>

<script>
export default {
	name: 'AppAnchorTel',
	props: {
		tel: String,
		inline: {
			type: Boolean,
			default: false,
		},
		showIcon: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.anchor-tel {
	display: flex;
	align-items: center;
	gap: 10px;
}

.anchor-tel__link {
	font-weight: 400;
}

.inline-block {
	display: inline-block;
}
</style>
