<template>
	<label
		class="file-input"
		:class="{ 'is-dragging-over': isDraggingOver }"
		tabindex="0"
		@keypress.enter="$refs.file_input.click()"
		@drop.prevent="addFileListener"
		@dragover.prevent="dragOverListener"
		@dragend.prevent="dragEndListener"
		@dragleave.prevent="dragEndListener"
		:for="$attrs.id"
	>
		<Fa weight="r" :icon="icon" />
		Dra och släpp / bläddra
		<input
			type="file"
			v-bind="$attrs"
			:required="false"
			ref="file_input"
			:multiple="multiple"
			@change="fileInputListener"
		/>
	</label>
</template>

<script>
export default {
	name: 'AppFileUploadInput',
	inheritAttrs: false,
	props: {
		icon: {
			type: String,
			default: 'paperclip',
		},
		multiple: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isDraggingOver: false,
		};
	},
	computed: {
		cssAttrs() {
			return {
				cursor: this.$attrs?.disabled ? 'not-allowed' : 'pointer',
				backgroundColor: this.$attrs?.disabled ? '#f1f0f1' : '#fff',
			};
		},
	},
	methods: {
		addFileListener(event) {
			const dropped_files = event.dataTransfer.files;

			this.isDraggingOver = false;

			if (!dropped_files) {
				return;
			}

			this.$emit('filesSelected', [...dropped_files]);
		},
		dragOverListener() {
			this.isDraggingOver = true;
		},
		dragEndListener() {
			this.isDraggingOver = false;
		},
		fileInputListener() {
			const file_input = this.$refs.file_input;

			this.$emit('filesSelected', [...file_input.files]);

			file_input.value = '';
		},
	},
};
</script>

<style scoped>
input[type='file'] {
	display: none;
}

.file-input {
	font-size: 14px;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border: 1px dashed var(--colorMuted);
	border-radius: 4px;
	margin: 0;
	padding: 15px 0;
	cursor: v-bind(cssAttrs[ 'cursor' ]);
	background-color: v-bind(cssAttrs[ 'backgroundColor' ]);
}

.is-dragging-over {
	background: #f1f1f1;
}
</style>
