var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade","appear":""}},[_c('div',{staticClass:"modal-mask"},[_c('transition',{attrs:{"name":"modal-container","appear":""}},[_c('div',{staticClass:"modal",class:{
					large: _vm.large,
					medium: _vm.medium,
					'modal--app-bg': _vm.appBg,
					'modal--photo': _vm.isPhoto,
					'modal--max-width': _vm.maxWidth,
				},attrs:{"tabindex":"0"}},[(_vm.header)?_c('header',{staticClass:"modal-header"},[_vm._t("header"),(_vm.actions)?_c('div',{staticClass:"modal-header-actions"},[_vm._t("actions"),(!_vm.hideCloseButton)?_c('button',{staticClass:"modal-close-btn",attrs:{"type":"button","title":"Avbryt"},on:{"click":_vm.clickCloseListener}},[_c('Fa',{staticClass:"t-neutral",attrs:{"weight":"r","icon":"times"}})],1):_vm._e()],2):_vm._e()],2):_vm._e(),_c('div',{staticClass:"modal-body"},[_vm._t("default")],2),(_vm.footer)?_c('footer',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [(!_vm.emptyFooter)?_c('button',{staticClass:"t-success",attrs:{"type":"button"},on:{"click":_vm.clickCloseListener}},[_c('Fa',{attrs:{"weight":"r","icon":"check-circle"}}),_vm._v(" OK ")],1):_vm._e()]})],2):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }