import Vue from 'vue';
import Vuex from 'vuex';
import * as types from '@/mutationTypes';
import auth from '@/modules/authentication/auth';
import testify from '@/modules/testify/store';
import sharepointItemStatus from '@/modules/sharepoint/store';
import supplierInvoice from '@/modules/supplier_invoice/store';
import appHeaderTypes from '@/core/appHeaderTypes';

Vue.use(Vuex);

const store = new Vuex.Store({
	strict: true,
	modules: {
		app: {
			namespaced: true,
			state: () => ({
				loadingCount: 0,
				appVersion: undefined,
				lastSentryEventId: undefined,
				subMenuLinks: [],
				showHeaderDropdown: {
					testify: false,
					notification: false,
				},
				latestUrlData: {},
				showSubMenu: false,
				showMainMenu: false,
				showPersonalMenu: false,
			}),
			getters: {
				appVersion(state) {
					return state.appVersion;
				},
				subMenuLinks(state) {
					return state.subMenuLinks;
				},
				isLoading(state) {
					return state.loadingCount > 0;
				},
				showTestifyHeaderDropdown(state) {
					return state.showHeaderDropdown.testify;
				},
				showNotificationHeaderDropdown(state) {
					return state.showHeaderDropdown.notification;
				},
				latestUrlData(state) {
					return state.latestUrlData;
				},
				showSubMenu(state) {
					return state.showSubMenu;
				},
				showMainMenu(state) {
					return state.showMainMenu;
				},
				showPersonalMenu(state) {
					return state.showPersonalMenu;
				},
			},
			mutations: {
				[types.app.INCREMENT_LOADING_COUNT](state) {
					++state.loadingCount;
				},
				[types.app.DECREMENT_LOADING_COUNT](state) {
					if (state.loadingCount > 0) {
						--state.loadingCount;
					}
				},
				[types.app.SET_APP_VERSION](state, newVersion) {
					state.appVersion = newVersion;
				},
				[types.app.SET_LAST_SENTRY_EVENT_ID](state, eventId) {
					state.lastSentryEventId = eventId;
				},
				[types.app.SET_SUB_MENU_LINKS](state, payload) {
					state.subMenuLinks = payload;
				},
				[types.app.PURGE_SUB_MENU_LINKS](state) {
					state.subMenuLinks = [];
				},
				[types.app.TOGGLE_HEADER_DROPDOWNS](state, data) {
					switch (data) {
						case appHeaderTypes.NOTIFICATION:
							state.showHeaderDropdown.notification = !state.showHeaderDropdown.notification;
							state.showHeaderDropdown.testify = false;
							break;
						case appHeaderTypes.TESTIFY:
							state.showHeaderDropdown.testify = !state.showHeaderDropdown.testify;
							state.showHeaderDropdown.notification = false;
							break;
						default:
							state.showHeaderDropdown.notification = false;
							state.showHeaderDropdown.testify = false;
					}
				},
				[types.app.SET_LATEST_URL_DATA](state, data) {
					state.latestUrlData = data;
				},
				[types.app.TOGGLE_SUB_MENU](state) {
					state.showSubMenu = !state.showSubMenu;
				},
				[types.app.CLOSE_SUB_MENU](state) {
					state.showSubMenu = false;
				},
				[types.app.TOGGLE_MAIN_MENU](state) {
					state.showMainMenu = !state.showMainMenu;
				},
				[types.app.CLOSE_MAIN_MENU](state) {
					state.showMainMenu = false;
					state.showPersonalMenu = false;
				},
				[types.app.TOGGLE_PERSONAL_MENU](state) {
					state.showPersonalMenu = !state.showPersonalMenu;
				},
				[types.app.CLOSE_PERSONAL_MENU](state) {
					state.showPersonalMenu = false;
				},
			},
		},
		auth,
		testify,
		supplierInvoice,
		sharepointItemStatus,
	},
});

import('@/modules/project/store').then((module) => {
	store.registerModule('project', module.default);
});

import('@/modules/company/store').then((module) => {
	store.registerModule('company', module.default);
});

import('@/modules/office/store').then((module) => {
	store.registerModule('office', module.default);
});

import('@/modules/accounting_year/store').then((module) => {
	store.registerModule('accountingYear', module.default);
});

import('@/modules/information/store').then((module) => {
	store.registerModule('information', module.default);
});

if ('requestAnimationFrame' in window) {
	const rafCallback = (time) => {
		store.dispatch('testify/loadAwaitCounts', time).then((c) => void c);

		requestAnimationFrame(rafCallback);
	};

	requestAnimationFrame(rafCallback);
}

import('@/modules/motorpool/store').then((module) => {
	store.registerModule('motorpool', module.default);
});

import('@/modules/project_email/store').then((module) => {
	store.registerModule('projectEmail', module.default);
});

import('@/modules/project_entry/store').then((module) => {
	store.registerModule('projectEntry', module.default);
});

import('@/modules/customer_inquiry/store').then((module) => {
	store.registerModule('customerInquiry', module.default);
});

export const moduleCommit = (namespace, name, payload) => {
	store.commit(`${namespace}/${name}`, payload);
};

export default store;
