import Vue from 'vue';
import './registerServiceWorker';
import * as types from '@/mutationTypes';

// Initiate Sentry early
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
// Components
import Fa from './core/components/Fa';
import ToggleCheckbox from './core/components/ToggleCheckbox';
import ToggleCheckbox2 from './core_2/components/toggle/ToggleCheckbox2';
import FaCheckbox from './core/components/FaCheckbox';
import AppEditor from './core/components/AppEditor';
import AppFormInput from './core/components/AppFormInput';
import AppFormTextarea from './core/components/AppFormTextarea';
import AppFormAttachments from './core/components/AppFormAttachments';
import AppAttachments from './core/components/AppAttachments';
import AppFormButton from './core/components/AppFormButton';
import AppFormCompanySelect from '@/core/components/AppFormCompanySelect';
import AppFormOfficeSelect from '@/core/components/AppFormOfficeSelect';
import AppAccordion from '@/core/components/AppAccordion';
import AppModal from '@/core/components/AppModal';
import AppFileUploadInput from '@/core/components/AppFileUploadInput';
import AppAnchorTel from '@/core/components/AppAnchorTel';
import AppAnchorEmail from '@/core/components/AppAnchorEmail';
import AppPageHeader from '@/core/components/AppPageHeader';
import AppFormProjectSearch from '@/core/components/AppFormProjectSearch.vue';
import AppFormInputEmail from '@/core_2/components/inputs/AppFormInputEmail.vue';
import AppFormInputText from '@/core_2/components/inputs/AppFormInputText.vue';
import AppFormInputNumber from '@/core_2/components/inputs/AppFormInputNumber.vue';
import AppFormInputTel from '@/core_2/components/inputs/AppFormInputTel.vue';
import AppFormSelectEntity from '@/core_2/components/selects/AppFormSelectEntity.vue';
import AppFormSelectEnum from '@/core_2/components/selects/AppFormSelectEnum.vue';
import store, { moduleCommit } from '@/store';
import moment from 'moment';
import 'moment/locale/sv';
import MasonryLayout from '@/core_2/layouts/MasonryLayout';
import AppButton from '@/core_2/components/AppButton.vue';
// Plugins and external components
import VueSelect from 'vue-select';
import VueFlashMessage from 'vue-flash-message';
import PortalVue from 'portal-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueMasonry from 'vue-masonry-css';

// CSS
import './assets/normalize.css';
import './assets/fontawesome.css';
import './assets/variables.css';
import './assets/app.css';
import './assets/variables.css';
import 'vue-select/dist/vue-select.css';
import './assets/vue-select.css';

if (process.env.VUE_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new Integrations.Vue({
				Vue,
				attachProps: true,
			}),
		],
		environment: process.env.NODE_ENV,
		beforeSend(event) {
			moduleCommit('app', types.app.SET_LAST_SENTRY_EVENT_ID, event.event_id);
			return event;
		},
	});
}

moment.locale('sv');

Vue.config.productionTip = false;

Vue.use(VueMasonry);

Vue.component('Fa', Fa);
Vue.component('ToggleCheckbox', ToggleCheckbox);
Vue.component('ToggleCheckbox2', ToggleCheckbox2);
Vue.component('FaCheckbox', FaCheckbox);
Vue.component('AppEditor', AppEditor);
Vue.component('AppFormInput', AppFormInput);
Vue.component('AppFormTextarea', AppFormTextarea);
Vue.component('AppFormAttachments', AppFormAttachments);
Vue.component('AppAttachments', AppAttachments);
Vue.component('AppFormButton', AppFormButton);
Vue.component('AppFormCompanySelect', AppFormCompanySelect);
Vue.component('AppFormOfficeSelect', AppFormOfficeSelect);
Vue.component('AppAccordion', AppAccordion);
Vue.component('AppModal', AppModal);
Vue.component('AppFileUploadInput', AppFileUploadInput);
Vue.component('VueSelect', VueSelect);
Vue.component('AppAnchorTel', AppAnchorTel);
Vue.component('AppAnchorEmail', AppAnchorEmail);
Vue.component('AppPageHeader', AppPageHeader);
Vue.component('AppFormProjectSearch', AppFormProjectSearch);
Vue.component('MasonryLayout', MasonryLayout);
Vue.component('tippy', TippyComponent);
Vue.component('AppButton', AppButton);
Vue.component('AppFormInputText', AppFormInputText);
Vue.component('AppFormInputNumber', AppFormInputNumber);
Vue.component('AppFormInputTel', AppFormInputTel);
Vue.component('AppFormInputEmail', AppFormInputEmail);
Vue.component('AppFormSelectEnum', AppFormSelectEnum);
Vue.component('AppFormSelectEntity', AppFormSelectEntity);

Vue.use(VueFlashMessage, {
	messageOptions: {
		timeout: 8000,
	},
});
Vue.use(PortalVue);
Vue.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	flipDuration: 0,
	animation: 'shift-toward',
	arrow: true,
});

const setSentryContext = (user) => {
	if (!user || !process.env.VUE_APP_SENTRY_DSN) {
		return;
	}

	Sentry.configureScope((scope) => {
		const userContext = {
			id: user.id,
			username: user.username,
			email: user.email,
		};

		if (user.crm_user) {
			userContext.crm_svc_user = user.crm_user.id;
			userContext.crm_svc_email = user.crm_user.email;
		}

		if (user.impersonating_user) {
			userContext.user_impersonating_id = user.impersonating_user.id;
			userContext.user_impersonating_username = user.impersonating_user.username;
		}

		scope.addEventProcessor((event) => {
			event.release = store.getters['app/appVersion'];
			return event;
		});

		scope.setUser(userContext);
	});
};

store.watch(
	(state) => state.auth.authenticatedUser,
	(user) => {
		if (!user) {
			return;
		}

		setSentryContext(user);

		store.dispatch('company/load')?.then((r) => void r);
		store.dispatch('office/load')?.then((r) => void r);
		store.dispatch('accountingYear/load')?.then((r) => void r);
		store.dispatch('information/loadDueSignatures')?.then((r) => void r);
	},
);

store.dispatch('auth/getSession').finally(() => {
	import('./web.js');
});
